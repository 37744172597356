import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getUser, isUserAuthenticated } from "../../utils/authService";
import ProductsTable from '../../components/ProductsTable';
import FilterBar from '../../components/FilterBar';
import { Box, Button, Card, CardContent, CardMedia, Grid, Tooltip, Typography } from "@mui/material";
import FilterBarW2W from "../../components/FilterBarW2W";

function Amazon2Walmart(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [filters, setFilters] = useState({ search: urlParams.has("asin") ? urlParams.get('asin') : "" });
  const [subscribed, setSubscribed] = useState(false);
  const [section, setSection] = useState(null);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    if (section !== "retailers") {
      setSuppliers([]);
    } else if (section === "retailers") {
      fetch(`https://server.nepeto.com/moa_load_sources/${getUser()}/`)
        .then((response) => response.json())
        .then((result) => { setSuppliers(result.filter(item => !["doitbest-excel", "ericksonmfg", "doitbest", "walmart"].includes(item))) })
    }
  }, [section]);

  useEffect(() => {
    const loadProducts = async () => {
      fetch(`https://server.nepeto.com/moa_load_a2w_products_by_page/${getUser()}/${page}/${JSON.stringify(filters)}/${section}/`)
        .then((response) => response.json())
        .then((result) => {
          if (result === "No permissions") {
            navigate("/pricing");
          } else {
            if (section === "w2w") {
              setData(result);
              setIsProductsLoading(false);
            }
            if (result.length > 0 && !props.isMobile && !(new Date(result[0].title.split("Last Update: ")[1]).toDateString().includes("Invalid"))) {
              let filteredData = result.filter(product => (((new Date() - new Date(product.title.split("Last Update: ")[1])) / 1000 / 60 / 60) <= 168))
              if (filteredData.length > 0) {
                setData(filteredData.sort((a, b) => 0.5 - Math.random()));
                setIsProductsLoading(false);
              } else {
                setPage(page => page + 1);
              }
            } else {
              setData(result);
              setIsProductsLoading(false);
            }
          }
        })
        .catch(() => {
          setIsProductsLoading(false);
        });
    };

    const checkAccess = async () => {
      setIsProductsLoading(true);
      fetch(`https://server.nepeto.com/moa_sub_check/${getUser()}/`)
        .then((response) => response.json())
        .then((result) => {
          setSubscribed(result);
          if (isUserAuthenticated() && result) {
            if (section !== null) {
              loadProducts();
            }
          } else {
            window.location.href = "/pricing"
          }
        })
        .catch(() => {
        });
    };
    checkAccess();
  }, [page, filters, props.isMobile, section]);

  if (!isProductsLoading && !subscribed) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>MOA - Products Page</title>
          <meta
            name="description"
            content="Explore products. Discover profitable opportunities."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, a2w, products, leads, amazon flips, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use MOA, sign up and subscribe to our plan.
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() => navigate('/login')}
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  if (section === null) {
    return <div style={{ textAlign: "center" }}>
      <Helmet>
        <title>MOA - Products Page</title>
        <meta
          name="description"
          content="Explore products. Discover profitable opportunities."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, a2w, products, leads, amazon flips, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <br />
      <br />
      <br />
      <h1>Choose</h1>
      <br />
      <br />
      <br />
      <div style={{ display: "inline-flex", gap: "16px" }}>
        <button
          style={{
            padding: "12px 24px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            fontSize: "1.75vw",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "orange",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "darkorange")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "orange")}
          onClick={() => setSection("amazon")}
        >
          Amazon to Walmart
          <br /><br />
          <img src="/assets/a2w.png" style={{ width: "20vw", mixBlendMode: "multiply" }} />
        </button>
        <button
          style={{
            padding: "12px 24px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            fontSize: "1.75vw",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#28a745",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#1e7e34")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#28a745")}
          onClick={() => setSection("retailers")}
        >
          Retailers to Walmart
          <br /><br />
          <img src="/assets/r2w.png" style={{ width: "20vw", mixBlendMode: "multiply" }} />
        </button>
        <button
          style={{
            padding: "12px 24px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            fontSize: "1.75vw",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#007bff",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
          onClick={() => setSection("w2w")}
        >
          Walmart to Walmart
          <br /><br />
          <img src="/assets/w2w.png" style={{ width: "20vw", mixBlendMode: "multiply" }} />
        </button>
      </div>
    </div>
  }

  return (
    <>
      <Helmet>
        <title>MOA - Products Page</title>
        <meta
          name="description"
          content="Explore products. Discover profitable opportunities."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, a2w, leads, products, amazon flips, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#CC9901",
          marginBottom: props.isMobile && "1vh",
        }}
      >
        {section === "w2w" ? "W2W" : section === "retailers" ? "Retailers" : "A2W"} Products Page
      </h1>
      {section === "w2w" ?
        <>
          <FilterBarW2W
            data={data}
            setFilters={setFilters}
            filters={filters}
            section={section}
            suppliers={suppliers}
          />
          <Grid container spacing={4} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {isProductsLoading ? <span style={{ textAlign: "center", margin: "auto", marginTop: "5%" }}>Loading...</span> : data.map((props, index) => (
              <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{
                  width: 300,
                  borderRadius: "16px",
                  boxShadow: 10,
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflow: "hidden",
                  textAlign: "center"
                }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={props.thumbnail_url ? props.thumbnail_url : "/logo512.png"}
                    alt="Product Image"
                    style={{ objectFit: "contain", paddingTop: 10, borderRadius: "25px", mixBlendMode: "multiply" }}
                  />
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Tooltip title={props.name} arrow>
                      <Typography variant="h5" component="div">
                        {props.name ? (props.name.slice(0, 25) + "...") : "N/A"}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                      Item Id: <a target="_blank" rel="noopener noreferrer" href={"https://www.walmart.com/ip/" + props.item_id}>{props.item_id}</a><br />
                      UPC: {props.upc}<br />
                      Category: {props.category}
                    </Typography>
                    <br />
                    <Box
                      sx={{
                        border: "2px solid #1976d2",
                        borderRadius: "8px",
                        padding: "16px",
                        textAlign: "center",
                        backgroundColor: "#e3f2fd",
                        width: "fit-content",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "auto"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: "gray" }}
                      >
                        Was: ${props.was}<span style={{ fontSize: "60%" }}>(+${(props.was - props.now).toFixed(1)})</span>
                      </Typography>
                      <Typography variant="h4" sx={{ color: "#d32f2f", fontWeight: "bold" }}>
                        Now: ${props.now}
                      </Typography>
                      <Typography variant="h6" sx={{ color: "#388e3c", fontWeight: "bold" }}>
                        Dropped {(((props.was - props.now) / props.was) * 100).toFixed(0)}%!
                      </Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="primary">
                        Found Date: {new Date(props.date).toLocaleString()}
                      </Typography>
                    </Box>

                    <Button variant="contained" size="small" color='success' sx={{ mt: 2 }} onClick={() => window.open("https://histomart.com/kp?item_id=" + props.item_id)}>
                      Full Data
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <br /><br />
          <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: 15, }}>
            <Button
              variant={page === 1 ? "outlined" : "contained"}
              onClick={() => { setPage(p => p - 1); window.location.href = '#'; }}
              disabled={page === 1}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: page === 1 ? '#9e9e9e' : '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: page === 1 ? '#FCF8F3' : '#E6AF0A',
                border: `1px solid ${page === 1 ? '#9e9e9e' : '#FCF8F3'}`,
              }}
            >
              Previous Page
            </Button>
            <span>Page {page}</span>
            <Button
              variant={"contained"}
              onClick={() => { setPage(p => p + 1); window.location.href = '#'; }}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: ((page) * 10) > data.length ? '#9e9e9e' : '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: ((page) * 10) > data.length ? '#FCF8F3' : '#E6AF0A',
                border: `1px solid ${((page) * 10) > data.length ? '#9e9e9e' : '#FCF8F3'}`,
              }}
            >
              Next Page
            </Button>
          </div>
        </> :
        <>
          <FilterBar
            data={data}
            setFilters={setFilters}
            filters={filters}
            section={section}
            suppliers={suppliers}
          />

          <ProductsTable
            data={data}
            setPage={setPage}
            page={page}
            isMobile={props.isMobile}
            setFilters={setFilters}
            filters={filters}
            isLoading={isProductsLoading}
            section={section}
          /></>}
    </>
  );
}
export default Amazon2Walmart;
