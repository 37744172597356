import React, { useState } from 'react';
import { Box, Button, Modal, Typography, List, ListItem, ListItemText, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function SavedFilters({ open, setOpen, getFilters, onLoadFilter }) {
  const [filters, setFilters] = useState(() => {
    const storedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    return storedFilters;
  });
  
  const [newFilterName, setNewFilterName] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleClose = () => {
    setShowInput(false);
    setOpen(false);
  };

  const handleDelete = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
    localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
  };

  const handleSave = () => {
    setShowInput(true);
  };

  const handleSaveName = () => {
    if (newFilterName.trim() !== '') {
      const filterToSave = getFilters();
      const objectToSave = { name: newFilterName, filters: filterToSave };
      const updatedFilters = [...filters, objectToSave];
      setFilters(updatedFilters);
      localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30vw',
          bgcolor: '#FCF8F3',
          borderRadius: 2,
          border: '4px solid rgba(230, 175, 10, 0.5)',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography variant="h6" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
          Manage Saved Filters
        </Typography>
        {filters.length === 0 ? (
          <Typography>No saved filters.</Typography>
        ) : (
          <List>
            {filters.map((filter, index) => (
              <ListItem key={filter.name || `f-${index}`} button onClick={() => onLoadFilter(filter.filters)}>
                <ListItemText primary={filter.name || `Filter ${index + 1}`} />
                <IconButton edge="end" onClick={(e) => { e.stopPropagation(); handleDelete(index); }}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}

        {showInput && (
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Filter Name"
              variant="outlined"
              fullWidth
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleSaveName}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#769358',
                border: '1px solid #FCF8F3',
              }}
            >
              Save Filter
            </Button>
          </Box>
        )}

        {!showInput && (
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#B33B3B',
                border: '1px solid #FCF8F3',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#769358',
                border: '1px solid #FCF8F3',
              }}
            >
              Save current filters
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default SavedFilters;
